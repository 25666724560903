import Link from 'next/link'
import Image from 'next/image'

import { imgPath } from '../data'

import styles from './footer-logo.module.scss'

const FooterLogo = () => (
  <div className={styles.firstColumnWrapper}>
    <div className={styles.logoWrapper}>
      <Link href="/">
        <Image
          src={imgPath.logo_medp}
          width="138"
          height="31"
          alt="medpoint logo"
          className={styles.medpointLogo}
        />
      </Link>
      <div className={styles.fakeBorder}></div>
      <a href="https://onpoint.ru/" target="_blank" rel="noreferrer" className={styles.oniLogo}>
        <Image src={imgPath.logo_onpoint} width="84" height="17" alt="Перейти на сайт Onpoint" />
      </a>
    </div>
  </div>
)

export default FooterLogo
